import React,{useEffect, useState} from "react";
import { graphql } from 'gatsby'
import { Container, Row, Col, Form } from "react-bootstrap"
import Loadable from "@loadable/component"
import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Banner from '../components/Home/Banner/HomeBanner';
import OurNetwork from '../components/Home/OurNetwork/OurNetwork';


import Breadcrumbs from '../components/AreaGuideDetails/Breadcrumbs/Breadcrumbs';
import LandingBanner from '../components/AreaGuideDetails/AreaGuideBanner/LandingBanner';

import ServiceContentOne from '../components/AreaGuideDetails/About/About';
import ServiceContentTwo from '../components/AreaGuideDetails/OurMortgage/OurMortgage';
import BuyerFinder from '../components/DevelopmentDetails/ChartArea/ChartArea';
import WhyUse from '../components/WhyUse/WhyUse';
import Benefits from '../components/WhyUse/Benefits';
import StaticContent from '../components/StaticContent/StaticContent';
import GuidesLanding from '../components/Guides/GuidesLanding';
import NewsLanding from '../components/News/NewsLanding';

import Branches from "../components/Branches/Branches"
import VirtualTour from "../components/VirtualTour/VirtualTour"
import FAQSection from "../components/FAQ/FAQSection"
import FAQ from "../components/FAQ/FAQ"
import GuidesSection from "../components/Guides/HandyGuides"
import StaticContentSidebar from "../components/StaticContent/StaticContentSidebar"
import AccordionModule from "../components/Accordion/Accordion"
import AreaGuideLanding from "../components/AreaGuides/Landing"
import CaseStudiesLanding from "../components/CaseStudies/Landing"

import ValuationLanding from "../components/Valuation/Valuation"
import TrustPilot from "../components/TrustPilot.js"
import BudgetBooster from "../components/BudgetBooster"
import CustomerStory from "../components/CustomerStory"
import MortgageLanding from "../components/calculators/mortgage-landing"
import CalculatorLanding from "../components/CalculatorLanding.js"
import History from "../components/History/History"
import CaseStudySection from "../components/CaseStudies/LatestCaseStudies"
import BGImageLanding from "../components/BGImageLanding/BGImageLanding"
import ContactLanding from "../components/ContactLanding/ContactLanding"
import Accreditations from "../components/Accreditations/Accreditations"
import BookAnAppraisal from "../components/BookAnAppraisal/BookAnAppraisal"
import BookAViewing from "../components/BookAViewing/BookAViewing"
import Brands from "../components/AreaGuideDetails/OurBrands/OurBrands"
import MultiContent from "../components/Home/About/MultiRowsContent"
import RegisterLanding from "../components/RegisterLanding/RegisterLanding"
import Disclaimer from "../components/Disclaimer/Disclaimer"

const About = Loadable(() => import("../components/Home/About/About"))
const RecentVideo = Loadable(() => import("../components/Home/Videos/Videos"))
const NewsInsights = Loadable(() => import("../components/Home/NewsInsights/NewsInsights"))
const GetKnow = Loadable(() => import("../components/Home/GetKnow/GetKnow"))
const FormBlock = Loadable(() => import("../components/Home/FormBlock/FormBlock"))
const CountBlock = Loadable(() => import("../components/Home/CountBlock/CountBlock"))
const GlobalCount = Loadable(() => import("../components/Home/CountBlock/GlobalCount"))
const CountBlockLeftContent = Loadable(() => import("../components/Home/CountBlock/CountBlockLeftContent"))
const BookValuation = Loadable(() => import("../components/Home/BookValuation/BookValuation"))

const Modules = (props) => {

    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
      window.addEventListener("mousemove", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      })
      window.addEventListener("touchmove", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      })
      window.addEventListener("keypress", () => {
        if (renderComponent === false) {
            setRenderComponent(true)
        }
    }) 
     
  }, [])


    return (
        <React.Fragment>
            <SEO title={props.page.Meta_Title} description={props.page.Meta_Description} image={props.page.Banner_Image ? props.page.Banner_Image.url : ''}/>


            <div className={props.page.Layout === 'Homepage' ? "home-header" : props.page.Layout === 'Default' ? 'wrapper area-guide-wrapper' : props.page.Layout === 'Staticpage' || props.page.Layout === 'Valuation' || props.page.Layout === 'Background_Image_Layout' || props.page.Layout === 'Calculator_or_Form' ? 'wrapper static-page' : ''}>
                <Header />

                {props.page.Layout === 'Homepage' && props.page.Banner_Image && props.page.Choose_Menu[0].URL === 'home' &&
                    <Banner {...props.page} />
                }

                {props.page.Layout === 'Homepage' && props.page.Banner_Image && props.page.Choose_Menu[0].URL !== 'home' &&
                    <Banner {...props.page} />
                }

                {props.page.Layout === 'Default' && props.page.Banner_Image &&
                    <>
                        <Breadcrumbs {...props.page} />
                        <LandingBanner {...props.page} />
                    </>
                }

                {props.page.Layout === "Staticpage" &&
                    <Breadcrumbs {...props.page} />
                }

                {props.page.Layout === "Valuation" &&
                    <Breadcrumbs {...props.page} />
                }

                {props.page.Layout === 'Calculator_or_Form' && props.page.Banner_Image &&
                    <>
                        <Breadcrumbs {...props.page} />
                    </>
                }

                {props.page.Layout === 'Background_Image_Layout' && props.page.Banner_Image &&
                    <BGImageLanding page={props.page} module={props.modules} />
                }


                {props.page.Layout === 'Valuation' && props.page.Banner_Image &&
                    <ValuationLanding page={props.page} module={props.modules} />
                }

                {props.page.Layout === 'Default' && props.page.Banner_Image === null &&
                    <>
                        <Breadcrumbs {...props.page} />
                    </>
                }

            </div>
            {
                props.modules && props.modules.map((module, i) => {
                    return (
                        <>
                            {module.Intro_Content &&
                                <About {...module} />
                            }

                            {module.Add_Service_Tile &&
                                <GetKnow {...module} />
                            }

                            {module.Add_Stats && renderComponent &&
                                <>
                                    {module.Statistics_Left_Content ?
                                        <CountBlockLeftContent {...module} /> : <CountBlock {...module} />
                                    }
                                </>
                            }

                            {module.Select_Module && module.Select_Module.CTA_1_Link == null &&
                                <FormBlock {...module.Select_Module} />
                            }

                            {module.Select_Module && module.Select_Module.CTA_1_Link &&
                                <BookValuation {...module.Select_Module} />
                            }

                            {module.Latest_News_Heading && module.Latest_News_Intro_Content && renderComponent &&
                                <NewsInsights {...module} />
                            }
                            {module.Video_Heading &&
                                <RecentVideo {...module} />
                            }

                            {module.Add_New_Block &&
                                <>
                                    {module.Add_New_Block.length >= 3 ?
                                        <>
                                            {module.Add_New_Block && module.Add_New_Block.slice(0, 1).map((service, i) => {
                                                return (
                                                    <ServiceContentOne {...service} service_length={module.Add_New_Block.length} />
                                                )
                                            })}
                                            {module.Add_New_Block.length > 1 &&
                                                <ServiceContentTwo {...module} />
                                            }
                                        </> : module.Add_New_Block.length == 2 ?
                                            <ServiceContentTwo {...module} cut="0" /> :
                                            <>
                                                {module.Add_New_Block && module.Add_New_Block.slice(0, 1).map((service, i) => {
                                                    return (
                                                        <ServiceContentOne {...service} service_length={module.Add_New_Block.length} />
                                                    )
                                                })}
                                            </>
                                    }

                                </>
                            }

                            {module.Choose_Module === "Our_Network" &&
                                <OurNetwork {...props} />
                            }

                            {module.Choose_Module === "Buyer_Finder_Chart" &&
                                <BuyerFinder />
                            }

                            {module.Choose_Module === "Why_Use" &&
                                <WhyUse />
                            }
                            {module.Choose_Module === "Budget_Booster_Calculator" &&
                                <Container className="budget">
                                    <Row>
                                        <Col lg={12}>
                                            <BudgetBooster {...props.page}/>
                                        </Col>
                                    </Row>
                                </Container>
                            }

                            {module.Choose_Module === "Mortgage_Calculators" &&
                                <MortgageLanding />
                            }

                            {module.Choose_Module === "History" &&
                                <History />
                            }

                            {module.Choose_Module === "Yield_Calculator" || module.Choose_Module === "Stamp_Duty_Calculator" ?
                                <CalculatorLanding page={props.page} custom_css={props.custom_css} name={module.Choose_Module} /> : ''
                            }

                            {module.Choose_Module === "Contact_Form" &&
                                <ContactLanding {...module} page={props.page} />
                            }


                            {module.Choose_Module === "Registration_Form" &&
                                <RegisterLanding {...module} page={props.page} />
                            }

                            {module.Choose_Module === "Book_An_Appraisal_Form_Sales" &&
                                <BookAnAppraisal {...module} page={props.page} type="sell"/>
                            }

                            {module.Choose_Module === "Book_An_Appraisal_Form_Lettings" &&
                                <BookAnAppraisal {...module} page={props.page} type="let"/>
                            }

                            {module.Choose_Module === "Book_A_Viewing_Form" &&
                                <BookAViewing {...module} page={props.page} />
                            }

                            {module.Add_Benefit &&
                                <Benefits  {...module} />
                            }

                            {module.Add_Static_Content &&
                                <>
                                    {module.Style == 'With_Sidebar' ?
                                        <StaticContentSidebar layout={props.page.Layout} {...module} {...props.page} count={i} /> : <StaticContent {...module} style={module.Style} count={i} />
                                    }
                                </>
                            }

                            {module.Select_Guide_Category &&
                                <GuidesLanding {...module} />
                            }

                            {module.Add_Customer_Story &&
                                <CustomerStory {...module} />
                            }

                            {module.Select_Collection === "Branches" &&
                                <Branches />
                            }

                            {module.Select_Collection === "Virtual_Tours" &&
                                <VirtualTour />
                            }

                            {module.Select_Collection === "News" &&
                                <NewsLanding />
                            }

                            {module.Select_Collection === "Area_Guides" &&
                                <AreaGuideLanding />
                            }

                            {module.Select_Collection === "Case_Studies" &&
                                <CaseStudiesLanding />
                            }

                            {module.Select_Collection === "Testimonials" &&
                                <TrustPilot />
                            }

                            {module.Select_Collection === "FAQs" &&
                                <FAQ />
                            }

                            {module.Add_Brand &&
                                <Brands {...module} />
                            }

                            {module.Select_FAQs &&
                                <FAQSection {...module} />
                            }

                            {module.Add_Accordion &&
                                <AccordionModule {...module} />
                            }

                            {module.Select_Guides_here &&
                                <GuidesSection {...module} />
                            }

                            {module.Select_Case_Studies_to_Show &&
                                <CaseStudySection {...module} />
                            }

                            {module.Add_Accreditation &&
                                <Accreditations {...module} />
                            }

                            {module.Stats_Title &&
                                <GlobalCount {...module} />
                            }

                            {module.Add_New_Column && props.page.Layout !== 'Background_Image_Layout' &&
                                <MultiContent {...module} />
                            }

                            {module.Add_Disclaimer_Content &&
                                <Disclaimer {...module}/>
                            }
                        </>
                    )
                })
            }
            {/* <OurNetwork/> */}
            <Footer search={props.page.Popular_Search} />
        </React.Fragment>
    )
}
export default Modules;
